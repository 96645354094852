<template>
  <v-container class="cpanel ma-1">
    <div v-if="CAMERA_POSITION" class="border-b border-grey-darkest mb-2 pb-2">
      <v-checkbox
        dark
        dense
        hide-details
        v-model="gridsVisible"
        label="Gridlines"
        @change="toggleGrid"
        class="caption"
      ></v-checkbox>
      <v-checkbox
        dark
        dense
        hide-details
        v-model="axisLinesVisible"
        label="Axis Lines"
        @change="toggleAxisLine"
        class="caption"
      ></v-checkbox>
      <v-btn small class="mt-2" @click="resetCameraPosition">
        Reset Camera
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      gridsVisible: true,
      axisLinesVisible: true,
    };
  },
  computed: {
    ...mapGetters({ CAMERA_POSITION: "viewport/cameraPosition" }),
  },
  methods: {
    ...mapMutations({
      setCameraPosition: "viewport/SET_CAMERA_POSITION",
      resetCameraRotation: "viewport/RESET_CAMERA_ROTATION",
      toggleGrids: "viewport/TOGGLE_GRIDS",
      toggleAxisLines: "viewport/TOGGLE_AXIS_LINES",
    }),
    resetCameraPosition() {
      this.setCameraPosition({ x: 18, y: 12, z: 8 });
      this.resetCameraRotation({ x: 7, y: 2, z: -3 });
    },
    toggleGrid() {
      this.toggleGrids(this.gridsVisible);
    },
    toggleAxisLine() {
      this.toggleAxisLines(this.axisLinesVisible);
    },
  },
};
</script>
